<template>
  <div class="main-content">
    <div class="main-content__inner __no-border __registration">
      <h2 class="help-center-title">{{ $t('helpCenterWelcome') }}</h2>
      <base-select
        class="help-center-search"
        label="title"
        :value="null"
        :options="allTopicsForSelect"
        :placeholder="$t('searchTopicPlaceholder')"
        @input="openTopic"
      />
      <div class="help-center-topics">
        <HelpCenterCard
          v-for="section in sections"
          :key="section.id"
          :id="section.id"
          :title="section.title"
          :topics="section.topics"
          :topicCount="section.topicCount"
          :icon="section.icon"
        />
        <ActionCard
          :title="$t('notFoundAnswer')"
          :description="$t('emailUs')"
          :actionText="$t('askQuestion')"
          @click="showModal"
        />
      </div>
    </div>

    <HelpCenterQuestionModal
      v-if="isModalVisible"
      @close="closeModal"
      @send="sendQuestion"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { whiteBackground } from '@/mixins/whiteBackground'
import HelpCenterCard from '@/components/ui/layout/card/HelpCenterCard'
import ActionCard from '@/components/ui/layout/card/ActionCard'
import HelpCenterQuestionModal from '@/components/ui/modals/HelpCenterQuestionModal'
import { helpCenterPage } from '@/mixins/helpCenterPage'

export default {
  name: 'HelpCenterPage',
  mixins: [
    whiteBackground,
    helpCenterPage,
  ],
  components: {
    HelpCenterQuestionModal,
    ActionCard,
    HelpCenterCard,
  },
  computed: {
    ...mapState({
      isModalVisible: state => state.ui.overlay,
    }),
  },
  methods: {
    showModal () {
      this.$store.commit('showOverlay')
    },
    closeModal () {
      this.$store.commit('hideOverlay')
    },
    async sendQuestion () {
      await this.$store.commit('hideOverlay')
    },
  },
}
</script>

<style scoped lang="scss">
.help-center-title {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 32px;
  text-align: center;
}

.help-center-search {
  max-width: 792px;
  margin: 0 auto 32px;
  color: $text-gray;
}

.help-center-topics {
  display: grid;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  grid-template-columns: 1fr 1fr 1fr;
}
</style>
