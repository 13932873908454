<template>
  <BasicCard class="action-card">
    <template #content>
      <div class="action-card--title"
           v-if="title"
           v-text="title"
      ></div>
      <div class="action-card--description"
           v-if="description"
           v-text="description"
      ></div>
      <slot name="action">
        <simple-button
          class="action-card--button"
          :value="actionText"
          @click="() => { $emit('click') }"
        />
      </slot>
    </template>
  </BasicCard>
</template>

<script>
import BasicCard from '@/components/ui/layout/card/BasicCard'

export default {
  name: 'ActionCard',
  props: {
    title: String,
    description: String,
    actionText: String,
  },
  components: {
    BasicCard,
  },
}
</script>

<style scoped lang="scss">
.action-card {
  text-align: center;

  &--title {
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    margin-bottom: 12px;
  }

  &--description {
    margin-bottom: 16px;
  }

  &--button {
    width: 100%;
  }
}
</style>
