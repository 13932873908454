<template>
  <BasicCard class="hc-card">
    <template #content>
      <div class="hc-card--title" v-text="title"></div>
      <ul class="card-topics-list">
        <li
          class="card-topics-list--item"
          v-for="topic in visibleTopics"
          :key="topic.id"
        >
          <router-link
            :to="topic.topicUrl"
            class="card-topics-list--item"
          >
            {{ topic.title }}
          </router-link>
        </li>
        <li>
          <router-link
            class="card-topics-list--view-all"
            :to="`/help-center-all/section/${id}`"
          >
            {{ $t('viewMoreQuestion', { count: topicCount }) }}
          </router-link>
        </li>
      </ul>
      <div class="hc-card--icon-container">
        <img
          class="hc-card--icon"
          :src="iconLink"
        />
      </div>
    </template>
  </BasicCard>
</template>

<script>
import BasicCard from '@/components/ui/layout/card/BasicCard'

export default {
  name: 'HelpCenterCard',
  components: { BasicCard },
  props: {
    title: String,
    icon: String,
    id: Number || String,
    topics: {
      type: Array,
      default: () => [],
    },
    topicCount: Number,
  },
  computed: {
    visibleTopics () {
      return this.topics
        .filter((topic, index) => {
          return index < 3
        })
        .map(topic => {
          topic.topicUrl = `/help-center-all/topic/${topic.slug}`
          return topic
        })
    },
    iconLink () {
      return this.icon ? this.icon : require(`@/assets/sm.svg`)
    },
  },
}
</script>

<style scoped lang="scss">
.hc-card {
  position: relative;
  display: grid;
  grid-template-columns: 11fr 3fr;

  &--title {
    grid-column-start: 1;
    grid-column-end: 14;
    font-size: 16px;
    font-weight: 700;
    line-height: 18px;
    margin-bottom: 16px;
  }

  &--icon-container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }

  &--icon {
    max-width: 100%;
    margin-left: auto;
  }
}
.card-topics-list {
  &--item {
    color: $text-black;
    margin-bottom: 16px;
    transition: 0.3s ease color;

    &:hover {
      color: $color-primary;
    }
  }

  &--view-all {
    font-size: 14px;
    color: $text-gray;
    text-decoration: none;
  }
}
</style>
