export function positive (value) {
  const number = Number(value)
  if (isNaN(number)) return false
  return number >= 0
}

export function lessThan (value, { max }) {
  const number = Number(value)
  const numberMax = Number(max)
  if (isNaN(number)) return false
  return numberMax > number
}

export function moreThan (value, { min }) {
  const number = Number(value)
  const numberMin = Number(min)
  if (isNaN(number)) return false
  return number > numberMin
}

export function isUrl (str) {
  let url

  try {
    url = new URL(str)
  } catch (e) {
    return false
  }

  return url.protocol === 'http:' || url.protocol === 'https:'
}

export function exclude (value, { list }) {
  return list.includes(value) === false
}
