<template>
  <div class="input-with-label" :class="{ '__label-left': labelLeft}">
    <div v-if="label" class="input-with-label__label">
      <span
        v-if="required"
        class="input-with-label__required"
      >
        *
      </span>
      {{label}}
      <slot name="label"></slot>
      <TooltipIcon
        v-if="tooltip"
        :title="tooltip.title"
        :text="tooltip.text"
      />
    </div>
    <slot name="input">
      <BaseInput
        :value="inputValue"
        :fullWidth="true"
        :disabled="disabled"
        :placeholder="placeholder"
        :invalid="invalid"
        :errors="[errorMessage]"
        :type="type"
        :sign="sign"
        :iconName="iconName"
        :name="name"
        @input="onInput"
        @blur="$emit('blur', $event)"
      />
    </slot>
  </div>
</template>

<script>
import BaseInput from 'smarket-ui/src/components/forms/BaseInput'
import TooltipIcon from './TooltipIcon'
import { defineRule, useField } from 'vee-validate'
import {
  email,
  integer,
  length,
  max,
  min,
  // eslint-disable-next-line camelcase
  min_value,
  regex,
  required
} from '@vee-validate/rules'
import { isUrl, positive, lessThan, moreThan, exclude } from '../../../helpers/validation-rules'

defineRule('required', required)
defineRule('email', email)
defineRule('min', min)
defineRule('regex', regex)
defineRule('length', length)
defineRule('max', max)
defineRule('integer', integer)
defineRule('minValue', min_value)
defineRule('positive', positive)
defineRule('lessThan', lessThan)
defineRule('moreThan', moreThan)
defineRule('exclude', exclude)
defineRule('isUrl', isUrl)

export default {
  name: 'InputWithLabelValidation',
  components: { TooltipIcon, BaseInput },
  props: {
    value: {
      type: [ String, Number ],
    },
    label: {
      type: String,
    },
    required: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: Object,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
    },
    valid: {
      type: Boolean,
    },
    errors: {
      type: Array,
    },
    type: {
      type: String,
      default: 'text',
    },
    sign: {
      type: String,
    },
    iconName: {
      type: String,
      default: '',
    },
    labelLeft: {
      type: Boolean,
    },
    rules: {
      type: [String, Object],
    },
    name: {
      type: String,
      required: true,
    },
  },
  setup (props) {
    const {
      value: inputValue,
      errorMessage,
      meta,
      validate,
      handleChange,
    } = useField(props.name, props.rules, {
      initialValue: props.value,
    })

    return {
      inputValue,
      errorMessage,
      handleChange,
      meta,
      invalid: !meta.valid,
      validate,
    }
  },
  computed: {
    invalid () {
      return this.errorMessage ? this.errorMessage.length > 0 : false
    },
  },
  methods: {
    async onInput (value) {
      this.$emit('input', value)
      this.handleChange(value)
    },
  },
  watch: {
    value (value) {
      this.handleChange(value)
    },
  },
}
</script>

<style lang="scss" scoped>
.input-with-label {
  padding-bottom: 25px;
  position: relative;

  &__label {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 9px;
    display: flex;
    align-items: center;
    white-space: nowrap;
  }

  &__required {
    color: $color-error;
    margin-right: 5px;
  }

  &.__label-left {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 6fr;
    grid-gap: 15px;
    align-items: start;
  }

  &.__label-left &__label {
    margin: 0;
    align-self: center;
  }
}
</style>
