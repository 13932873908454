<template>
  <div class="card">
    <slot name="content">
      <div class="content" v-html="content"></div>
    </slot>
  </div>
</template>

<script>
export default {
  name: 'BasicCard',
  props: {
    content: {
      type: String,
    },
  },
}
</script>

<style scoped lang="scss">
  .card {
    background-color: $color-hover;
    padding: 20px;
  }
</style>
