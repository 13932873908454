export const whiteBackground = {
  beforeRouteEnter (to, from, next) {
    document.querySelector('.content-wrap').style.backgroundColor = '#fff'
    next()
  },
  beforeRouteLeave (to, from, next) {
    document.querySelector('.content-wrap').style.backgroundColor = ''
    next()
  },
}
