import { mapGetters, mapState } from 'vuex'

export const helpCenterPage = {
  data () {
    return {
      query: '',
    }
  },
  computed: {
    ...mapState({
      sections: state => state.helpCenter.sections,
    }),
    ...mapGetters([
      'locale',
    ]),
    allTopics () {
      return this.sections ? this.sections.reduce((acc, section) => {
        if (section.topics) {
          acc.push(...section.topics)
        }
        return acc
      }, []) : []
    },
    allTopicsForSelect () {
      return this.allTopics
        .filter(topic => {
          return topic.title.includes(this.query)
        })
    },
  },
  watch: {
    locale: {
      immediate: true,
      async handler () {
        if (!this.sections) {
          await this.$store.dispatch('getHelpSections', this.locale)
        }
      },
    },
  },
  methods: {
    search (query) {
      this.query = query
    },
    openTopic (topic) {
      if (this.topic?.slug !== topic.slug) {
        this.$router.push(`/help-center-all/topic/${topic.slug}`)
      }
    },
  },
}
